import React from "react";

import logo from "../assets/images/mysankofa-logo-2.png";

export const AboutUs = () => {
  return (
    <div id="aboutUs">
      <h2 className="title">About Us</h2>
      <div className="container">
        <section className="aboutSankofa">
          <div className="paragraph">
            <h3>Formation:</h3>
            <p>
              In the heart of 2020 pandemic just at the time it has subsided,
              many individuals were uncertain and still afraid to come out
              Prince Joshua Kofi Morrison Budukuma, stood tall with courage and
              gave hope to various community members young and old alike to come
              out, put another dark chapter in human course behind them and keep
              living. People positively received his message and came out in
              droves to witness and be part of the{" "}
              <a href="/">
                <strong>MYSANKOFA GLOBAL ROYALTY</strong>
              </a>{" "}
              initiative. His thoughtful vision after many years has come to
              fruition and the community is loving it.
            </p>
          </div>
          <div className="imageContainer">
            <img src={ logo } alt="" />
          </div>
        </section>
        <section>
          <div className="imageContainer">
            <img src="" alt="" />
          </div>
          <div className="paragraph">
            <h3>About The Founder:</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Inventore reprehenderit corporis alias asperiores, assumenda
              doloribus veritatis perspiciatis, dolorum obcaecati laudantium
              odit in optio animi! Consequuntur quaerat magni perspiciatis alias
              ab sapiente, doloribus assumenda repellat in numquam ipsum nihil
              voluptates autem hic similique nemo tempore odio.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
