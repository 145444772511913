import React, { useEffect } from "react";

// import ReactPlayer from "react-player";
import VenoBox from "venobox";

import HeroSlider from "../Components/HeroSlider";

import missionSVG from "../assets/svg/mission.svg";
import valuesSVG from "../assets/svg/values.svg";
import visionSVG from "../assets/svg/vision.svg";

import video1 from "../assets/videos/Ohene Ne Ohemaa Da.mp4";
import poster from "../assets/images/royal-essence-poster.png";
import kingOnSeat from '../assets/images/king.jpg';

import videoThumbnail from "../assets/images/video-thumbnail.png";
import CountDown from "../Components/CountDown";

export const Home = () => {
  useEffect(() => {
    new VenoBox({
      selector: ".venobox",
      numeration: true,
      infinigall: true,
      share: true,
      spinner: "rotating-plane",
      navTouch: true,
      autoplay: true,
    });

    new VenoBox({
      selector: ".image",
      numeration: true,
      infinigall: true,
      share: true,
      spinner: "rotating-plane",
      navTouch: true,
      autoplay: true,
      maxWidth: "600px",
    });
  }, []);

  return (
    <div id="home">
      <HeroSlider />

      <section className="missionVisionValues">
        <div className="container mission">
          <div className="imageContainer">
            <img src={missionSVG} alt="" />
          </div>
          <h3>Mission</h3>
          <p>
            To strive for higher cultural and economic coperations, fostering
            stronger global relationships which would create opportunities for
            mutual benefit.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
        <div className="container vision">
          <div className="imageContainer">
            <img src={visionSVG} alt="" />
          </div>
          <h3>Vision</h3>
          <p>
            It is our vision to establish the African Diaspora Cultural Center
            where educational informative programs will be available to all
            especially, children, youth, and various communities at large to
            come learn and exchange knowledge. To engage and encourage youth to
            reach their highest potential. Additionally, we seek to collaborate
            with various education/institutions as well as other cultures on
            educational programs.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
        <div className="container values">
          <div className="imageContainer">
            <img src={valuesSVG} alt="" />
          </div>
          <h3>Values</h3>
          <p>
            To use cultural and traditional practices as means to building a
            secured cohesive community where people are empowered to believe in
            themselves, respect others, and live in harmony with a sense of
            purpose and community.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
      </section>
      <section className="gallery">
        <div className="videos">
          <a
            href={video1}
            data-vbtype="video"
            data-autoplay="true"
            className="video venobox"
          >
            <img src={videoThumbnail} alt="" />
          </a>
        </div>
      </section>
      <section className="royalEssence">
        <div className="container">
          <h3 className="hertina">Royal Essence</h3>
          <div className="countDown">
            <CountDown targetDate="2025-02-28T00:00:00" />
          </div>
        </div>
        <div className="imageContainer">
          <img src={ kingOnSeat } alt="" />
        </div>
      </section>

      <section className="contactUs">
        <div className="formContainer">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdvEZbxYN8iTF-KBN7kl9y9KwR0x6woy6i0n88rr5yM41XC6Q/viewform?embedded=true"
            width="100%"
            height="940px"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Contact Us Form"
          >
            Loading…
          </iframe>
        </div>
      </section>
    </div>
  );
};
