import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import logo from "./assets/images/logo.png";
import logo2 from "./assets/images/logo2.png";

import { Home } from "./Pages/Home";
import { PageNotFound } from "./Pages/PageNotFound";
import { Gallery } from "./Pages/Gallery";
import { ContactUs } from "./Pages/ContactUs";
import { AboutUs } from "./Pages/AboutUs";

function App() {
  const [scrolled, setScrolled] = useState("");
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 70) {
        setScrolled("");
      } else {
        setScrolled("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activateMenu = () => {
    setIsActive(isActive ? "" : "active");
  };

  return (
    <>
      <header className={scrolled + " " + isActive}>
        <nav className="primaryNavigation">
          <a href="/" className="logoContainer">
            <img src={logo} alt="" />
          </a>
          <ul className="menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/news">News</a>
            </li>
            <li>
              <a href="/donate">Donate</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
          <button onClick={activateMenu}>
            <i className="ri-menu-2-line"></i>
          </button>
        </nav>
      </header>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>

      <footer>
        <div className="container">
          <a href="/" className="imageContainer">
            <img src={logo2} alt="MYSANKOFA GLOBAL ROYALTY" />
          </a>
          <ul>
            <li>
              <a href="tel: +13234491147">
                <i className="ri-phone-line"></i>
                <span>+1 (323) 449-1147</span>
              </a>
            </li>
            <li>
              <a href="mailto: mysankofa27@gmail.com">
                <i className="ri-mail-line"></i>
                <span>mysankofa27@gmail.com</span>
              </a>
            </li>
          </ul>
          <ul className="menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/news">News</a>
            </li>
            <li>
              <a href="/donate">Donate</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
          <ul className="socials">
            <li>
              <a href="/">
                <i className="ri-facebook-circle-line"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="ri-instagram-line"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="ri-tiktok-line"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="ri-youtube-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default App;
